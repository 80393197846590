import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import EventExperienceEffect from "../../assets/img/trauma&Resilience/EventExperienceEffect.png";
import PopWords from "../../assets/img/trauma&Resilience/PopWords.png";
import TraumaImpact from '../../assets/img/trauma&Resilience/TraumaImpact.PNG';
import ACE from "../../assets/img/trauma&Resilience/ACE.png";
import Resilience from "../../assets/img/trauma&Resilience/resilience.png"
import TFSP from "../../assets/img/trauma&Resilience/TFSP.png"
import BalloonThoughts from "../../assets/img/trauma&Resilience/BalloonThoughts.png"
import PEC from "../../assets/img/trauma&Resilience/PCEs.png";
import Links from "../../components/Links";


const traumaAndResilience_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Trauma & Its Impact "
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                Trauma is the response to an event or events that are physically or emotionally harmful, and negatively impact well-being and functioning. Trauma can happen to individuals, families, and whole communities.  Rates of trauma are significantly higher among people who receive behavioral health services.  Trauma can happen to us directly, or we can witness or hear about it happening to others. There are many types of traumas, and often people experience multiple traumas over their lifespan.

                <img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden" }} src={PopWords} />
            </>,
            // <><img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden", }} src={EventExperienceEffect} /></>,
            <>
                {/* <b>There are many types of trauma: </b><br /><br />
                <ul>
                    <li>Natural Disasters:  floods, wildfires, tornadoes, hurricanes, etc.</li>
                    <li>Family or Household Trauma: domestic violence; child abuse or neglect; someone with mental illness or substance use/misuse in the household; someone in the household incarcerated; traumatic death of a family member </li>
                    <li>Unexpected Trauma:  car, boat or ATV accidents; housefires; accidental gunfire accidents; traumatic medical experiences; etc.</li>

                    <img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden" }} src={PopWords} />

                    <li>Societal Trauma:  discrimination, oppression, racism, war, terrorism, relocation, etc.</li>
                </ul> */}

                Trauma impacts everyone differently and there is no right or wrong response:  sometimes the impact is small or short-lived, for others it can be severe or last a long time.  Trauma can have a significant impact on an individual's health and well-being, and can lead to a sense of powerlessness, fear or hopelessness, and a constant state of alertness within an individual.  Here are some common responses to trauma:

                <img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden" }} src={TFSP} />

            </>,

            <>Learn more about trauma and its effects <a href="https://www.nimh.nih.gov/health/topics/coping-with-traumatic-events">here.</a></>,
            <>Learn more about the impact of trauma <a href="https://www.traumainformedcare.chcs.org/what-is-trauma/">here.</a><br /><br /></>
        ]
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 30,
        content: <><b>Trauma-Informed Care</b></>
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            <>
                Trauma-informed care is an approach to engaging people that recognizes the potential presence of trauma symptoms and acknowledges the role that trauma may play in an individual’s life.  Resilience-oriented approaches ensure that people not only survive, but are supported to thrive to try to reach their full potential.  When a human service agency becomes trauma-informed, every part of its organization, management and service delivery system is designed to be sensitive and responsive to recognizing how trauma affects the lives of individuals and their engagement in services.

                <br />
                <br />

                Trauma-informed, healing-centered, resilience-oriented care shifts the paradigm from asking “'What's wrong with you?” to asking: “What happened to you?" and “What’s strong with you?”

                <br />
                <br />

                <div class="">
                    <div class="row">
                        <div class="col-7 col-sm-4"><img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden" }} src={BalloonThoughts} /></div>
                        <div class="col">Learn more about trauma-informed approaches: <br />
                            <br />
                            <ul>
                                <li><a href="https://www.ncbi.nlm.nih.gov/books/NBK207201/">Trauma-Informed Care in Behavioral Health Services </a></li>
                                <li><a href="https://www.ncbi.nlm.nih.gov/books/NBK207201/">Key Ingredients for Successful Trauma-Informed Care</a></li>
                                <li><a href="https://www.traumainformedcare.chcs.org/what-is-trauma-informed-care/">Trauma-Informed Care Implementation Resource Center</a></li>

                            </ul>
                        </div>


                       


                    </div>
                </div>





            </>,
            <>

            </>,

            <>
                Looking for Training for your Organization?  See the <a href="https://dbhdid.ky.gov/documents/dbh/tic/Trauma-InfomedCareTrainersStatewideContactList12_8_21.pdf">list of Trauma-Informed Care Trainers.</a><br /><br />

                Want to become a Trauma-Informed Care Trainer?  Contact <a href="mailto:brittanya.barber@ky.gov">Brittany A. Barber</a>
                <br/>
                <br/>




            </>,
            // <>
            //     Trauma-informed, healing-centered, resilience-oriented care shifts the paradigm from asking “'What's wrong with you?” to asking: “What happened to you?" and “What’s strong with you?”
            // </>,

            // <>Trauma can happen to individuals or to families, groups or communities.</>,

            // <>Trauma can happen to us directly; or we can experience trauma by seeing, reading or hearing about the trauma of other people </>
            //     <>
            //         d.	Length of time we are impacted varies – some people have a short-term response, and resume their lives quickly; others feel the impact for many years, even the rest of their life; the impact can come and go – sometimes it is better or worse depending on levels of supports and stress, or things that remind us of the original trauma; there is no right or wrong



            //     </>,
            //     <>
            //         e.	Examples of the impact of trauma:
            //         <UnorderedList items={[
            //             "i.	Veteran ",
            //             "ii.	Child who experienced natural disaster",
            //             "iii.	Adult who has a history of childhood trauma",
            //             "iv.	Adolescent with traumatic grief",
            //             "v.	Example of trauma from media:  watching images of September 11, see images of war or police brutality, etc.",
            //         ]} />
            //     </>,
        ]
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 41,
        content: <><b>Positive and Adverse Childhood Experiences: PCEs and ACEs</b></>
    },

    {
        format: bodyHelperTypes.paragraphs,
        order: 42,
        content: [
            <>Adverse Childhood Experiences (ACEs) refer to many different types of traumatic events that can happen in childhood.  Research shows that ACEs increase risk for a variety of health, behavioral health and social concerns throughout life.  Learn more about <a href="https://www.cdc.gov/violenceprevention/aces/index.html">ACEs</a> and about <a href="https://developingchild.harvard.edu/resources/aces-and-toxic-stress-frequently-asked-questions/">ACEs and toxic stress</a>.</>,

            <><img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden" }} src={ACE} /></>,

            <>
                Research also demonstrates that Positive Childhood Experiences (PCEs) or protective factors, are powerful agents to reduce risk from childhood adversity.  The building blocks of positive experiences can help us build resilience to heal from the negative effects of trauma and adversity.
            </>,

            <><img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden" }} src={PEC} /></>,

            <>
                Learn more about the building blocks for positive experiences <a href="https://positiveexperience.org/wp-content/uploads/2023/03/The-Four-Building-Blocks-of-HOPE.pdf">here</a> and how positive childhood experiences build good adult mental health <a href="https://positiveexperience.org/wp-content/uploads/2020/03/BRFShandout2-18.pdf">here.</a>

            </>
        ]
    },


    {
        format: bodyHelperTypes.paragraph,
        order: 50,
        content: <><b>If you or someone you know is impacted by trauma</b></>
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [

            <><b>Promote Connection</b> - talk to someone you trust about how you are doing; <a href="https://988lifeline.org/?utm_campaign=Vibrant,988AwarenessQ1Q224&utm_source=Google&utm_medium=Search&gad_source=1 gclid=CjwKCAjwrIixBhBbEiwACEqDJXbC6I9nGOJAJnThICGoeIbVv_FCZsLRyRjKie8C5H4t55Fxpf5EWhoCDEoQAvD_BwE">call or text 988</a> if you are worried about your own or someone else’s safety. </>,
            <><b>Support Safety</b> - make sure you or the person are physically safe; help others feel psychologically safe by promoting trust through honesty, listening, and just being there.  After a natural disaster or loss, make sure basic needs (i.e. housing, food, clothing, transportation, communication) are met as quickly as possible.</>,
            <>
                <b>When should I seek support from a behavioral health professional?</b> You may want to seek a professional behavioral health provider (counselor, therapist, psychologist, etc.) if the effects of the trauma are getting in the way of daily functioning over a period of time.  For example, if you or someone you know are experiencing nightmares or flashbacks, or can’t stop thinking about what happened; avoiding certain places, people or things; can’t concentrate because you’re always on the alert for something bad to happen; or having persistent negative thoughts or feelings.  Learn about <a href="https://www.nimh.nih.gov/sites/default/files/documents/health/publications/post-traumatic-stress-disorder-ptsd/post-traumatic-stress-disorder_1.pdf">PTSD</a> here.

            </>

        ]
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 70,
        content: <><b>Building Resilience</b></>
    },

    {
        format: bodyHelperTypes.paragraphs,
        order: 80,
        content: [
            "Resilience is “the process of adapting well in the face of adversity, trauma, tragedy, threats, or significant sources of stress—such as family and relationship problems, serious health problems, or workplace and financial stressors” (American Psychological Association).  We can build resilience over time through:",
            <><img style={{ maxWidth: "100%", textAlign: "center !important", overflow: "hidden" }} src={Resilience} /></>,

            <>Resilience is important for everyone, and can be nurtured at anytime in our life.  Supporting resilience may be especially important for people exposed to trauma including veterans, persons facing medical concerns, persons living in new or unexpected situations, persons who have experienced natural disasters, persons experiencing traumatic loss, and persons in recovery. Learn about building resilience <a href="https://www.apa.org/topics/resilience/building-your-resilience">here</a> and about building childhood resilience <a href="https://developingchild.harvard.edu/science/key-concepts/resilience/">here.</a></>
        ]
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 90,
        content: <><b>Trauma & Resilience in Early Childhood, Childhood & Adolescence</b></>
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 100,
        content: [
            <>

                Exposure to trauma in childhood can have lasting impact, however, exposure to positive childhood experiences (PCEs) can counter those effects.  Learn more about how to prevent and respond to childhood trauma, and promote positive childhood experiences below.
                <br /><br />

                <Links linkTypeID="793" />


            </>




        ]
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 110,
        content: <><b>Trauma & Resilience and Mental Health</b></>
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 120,
        content: [
            <>Research indicates high rates of a history of trauma exposure among people with mental health challenges.  Although post-traumatic stress disorder (PTSD) is a diagnosis, it should be seen as a human reaction to an extraordinary experience, an attempt to survive.  Sometimes symptoms of PTSD co-occur with other mental health concerns and it’s important to ensure that trauma is considered in developing a treatment plan and diagnosis.  Learn more <a href="https://www.psychiatry.org/patients-families/ptsd/what-is-ptsd#:~:text=People%20with%20PTSD%20have%20intense,or%20estranged%20from%20other%20people.">here.</a></>



        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 130,
        content: "Trauma & Resilience and Substance Use Disorder (SUD) "
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 140,
        content: [

            <>
                Trauma exposure and substance use are closely connected: persons in treatment for substance use disorder report high rates of exposure to trauma in their lives.  For some, they may use substances as a means of coping with the effects of their traumatic experiences.  For others, their substance use may increase risk for experiencing trauma.  The link between SUD and trauma is true for youth and adults; co-occurring treatment to address both traumatic stress and substance use is recommended. Learn more about <a href="https://istss.org/public-resources/friday-fast-facts/substance-use-and-traumatic-stress">Substance Use and Traumatic Stress</a> and about <a href="https://www.nctsn.org/sites/default/files/resources/making_the_connection_trauma_substance_abuse.pdf">Making the Connection: Trauma and Substance Abuse</a> in families.
            </>

        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 150,
        content: [
            "Trauma & Resilience and Intellectual and Developmental Disabilities "
        ],
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 160,
        content: [

            <>
                Persons with developmental and intellectual disabilities may be at increased risk for experiencing some types of trauma, and for having significant effects across the lifespan.  Research indicates that people with developmental and intellectual disabilities who have experienced trauma may not receive needed services to address their traumatic stress.  See these resources about <a href="https://www.nctsn.org/what-child-trauma-populations-risk-intellectual-and-developmental-disabilities/nctsn-resources">Trauma and Children with Intellectual and Developmental Disabilities</a> and these <a href="https://vkc.vumc.org/assets/files/tipsheets/traumatips.pdf">Tips and Resources on Trauma and Individuals with Intellectual and Developmental Disabilities</a>.There are also many resources and trainings available at the <a href="https://www.mhddcenter.org/">Mental Health and Developmental Disabilities National Training Center</a>.
            </>

        ]
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 170,
        content: <><b>Trauma & Resilience at Work</b></>,
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 180,
        content: [

            <>

                Many jobs put workers in contact with trauma, either directly (e.g. first responders, disaster responders) or by working with people who have experienced trauma (health care providers, behavioral health providers, educators, other helping professionals).  It’s important to recognize when this exposure to trauma in the workplace may negatively impact professional well-being.  And, it’s important to know strategies that can help prevent, or reduce the effect of burnout, compassion fatigue, vicarious traumatization and secondary traumatic stress.  Learn more about how to recognize, prevent and manage stress from work at <a href="https://ctac.uky.edu/projects-and-programs/wellwork">Well@Work</a>. You can learn about professional well-being from this <a href="https://youtube.com/playlist?list=PLRXrSMzWu9rGbMMzjKNVcO3T144pbkLYA&feature=shared">video series</a>.


            </>,

            <>
                <i>
                    For more information about trauma and resilience, contact: <br />
                    <b><a href="mailto:brittanya.barber@ky.gov">Brittany A. Barber</a></b> <br />
                    Program Administrator for Trauma-Informed Care <br />
                    Department for Behavioral Health, Developmental and Intellectual Disabilities  <br />
                    <br />
                </i>
            </>



        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 190,
        content: [
            "Additional Links "
        ],
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 200,
        content: <><Links linkTypeID="794" /></>
    },
    {
        format: bodyHelperTypes.title,
        order: 210,
        content: [
            "Kentucky Resources"
        ],
    },
    {
        format: bodyHelperTypes.paragraph,
        order: 220,
        content: <><Links linkTypeID="798" /></>
    }








];

export default traumaAndResilience_Content;