import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";

import Links from "../../../components/Links";

const recordingsContent = [
    {
        format: bodyHelperTypes.paragraphs,
        order: 10,
        content: [
            <><Links linkTypeID="797" /></>
        ]
    },

    {
        format: bodyHelperTypes.title,
        order: 20,
        content: "Dream Weavers Series – March 2024 "
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 30,
        content: [
            <><Links linkTypeID="795" /></>
        ]
    },
    
    {
        format: bodyHelperTypes.title,
        order: 40,
        content: "Medicaid Eligibility for Waiver Services -  April 18, 2024"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 50,
        content: [
            <><Links linkTypeID="796" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 60,
        content: "Community Mental Health Centers I/DD Crisis Services - May 15, 2024"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 70,
        content: [
            <><Links linkTypeID="801" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 80,
        content: "Life Plan of KY and STABLE Accounts - June 20, 2024"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 90,
        content: [
            <><Links linkTypeID="802" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 100,
        content: "Supported Decision Making - July 18, 2024"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 110,
        content: [
            <><Links linkTypeID="803" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 120,
        content: "Medicaid Waiver Management Application (MWMA) - August 15, 2024"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 130,
        content: [
            <><Links linkTypeID="804" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 140,
        content: "Social Role Valorization (SRV)  and Employment - October 17, 2024 "
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 150,
        content: [
            <><Links linkTypeID="812" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 160,
        content: "Adult Protective Services - Thursday, November 21, 2024 "
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 170,
        content: [
            <><Links linkTypeID="813" /></>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 180,
        content: "Technology Assisted Residential Services - December 5, 2024"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 190,
        content: [
            <><Links linkTypeID="814" /></>
        ]
    },
    
    


];

export default recordingsContent;