import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";
import appleHeart from "../../../assets/img/mentalHealthPromotion/AppleHeart.jpg";

const mHPromotion_Content = [
    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "The Goals of Mental Health Promotion"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                <div style={{marginTop:"10px"}} className="container overlap-box">
                    <div className="clearfix">
                        <img src={appleHeart} className="container col-md-6 float-md-end mb-3 ms-md-3 Home-Image-Top-Box" alt="..." />
                        <ul>
                            <li>Increase awareness about mental health and wellness in Kentucky, including:
                                <ul>
                                    <li>definitions of mental health and wellness</li>
                                    <li>benefits of mental health and wellness</li>
                                    <li>disparities in mental health and wellness</li>
                                </ul>
                            </li>

                            <li>Promote mental wellness for all kentuckians through education, including
                                <ul>
                                    <li>risk factors for poor mental health</li>
                                    <li>strategies to improve mental health and wellness</li>
                                </ul>
                            </li>
                            <li>
                                Reduce stigma around mental health
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "What is Mental Wellness?"

    },

    {

        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [
            "Being mentally well is more than just the absence of a diagnosed mental health condition. It includesour overall emotional, psychological, and social well-being.",
            "Our level of mental wellness impacts our ability to cope with stress, engage in meaningful activities,and build positive relationships.",
            <>
                <div style={{ backgroundColor: "#50d39f", width: "", height: "100px", paddingTop: "20px" }}>
                    <h3 style={{ fontSize: "40px !important", paddingLeft: "20px", paddingTop: "20px" }}><b>EVERYONE HAS THE CAPACITY TO BE MENTALLY WELL</b></h3>
                </div><br/>

                <a href="https://dbhdid.ky.gov/documents/dbh/UnderstandingMentalWelness.pdf">Download the Understanding Mental Health and Wellness Resource Guide</a>

            </>


        ]

    },
    {

        format: bodyHelperTypes.title,
        order: 50,
        content: "Benefits of Mental Wellness The Mental Health Continuum Mental Health Checklist"

    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 60,
        content: [

            <>


            <b>The Benefits of Mental Wellness</b><br />

           
            "Being mentally well can have many good impacts on your overall physical, mental, social, and emotional wellbeing.",
            </>,
            <>
                <b>Focusing on your MENTAL WELLNESS can…</b><br /><br />
                <ol>
                    <li>Reduce risk of physical health problems such as heart disease, stroke, and type 2 diabetes.</li>
                    <li>Reduce risk of developing depression and anxiety.</li>
                    <li>Increase positive outlook and ability to enjoy life.</li>
                    <li>Increase ability to cope with stressful or difficult life events.</li>
                    <li>Increase creativity and openness to new experiences.</li>
                    <li>Improve relationships with the people in your life.</li>
                    <li>Increase productivity to allow you to reach your potential.</li>
                    <li>Enable you to make meaningful contributions to your family, community, and society.</li>
                    <li>Increase ability to think clearly and make healthy decisions.</li>
                    <li>Improve mood, self-esteem, and confidence.</li>
                </ol>
            </>,
            <>
                <div>
                    <b style={{ textAlign: "center !important" }}>REMEMBER: EVERYONE HAS THE CAPACITY TO BE MENTALLY WELL,
                        regardless of whether you have a mental health condition.</b>
                </div>
            </>,
            <>
                Download the <a href="">“10 Benefits of Mental Wellness” flyer</a>
            </>,
            <>
                Download the <a href="">"What is Mental Wellness"</a>
            </>,
            <>
                <b>LEARN MORE ABOUT THE BENEFITS OF CARING FOR YOUR MENTAL HEALTH:</b><br/>
                <a href="https://www.nimh.nih.gov/health/topics/caring-for-your-mental-health">https://www.nimh.nih.gov/health/topics/caring-for-your-mental-health</a>
                <a href="https://positivepsychology.com/benefits-of-mental-health/">https://positivepsychology.com/benefits-of-mental-health/</a>
                <a href="https://www.cdc.gov/mentalhealth/learn/index.htm">https://www.cdc.gov/mentalhealth/learn/index.htm</a>
            </>

        ]

    },

    
    // {
    //     format: bodyHelperTypes.paragraph,
    //     order: 11,
    //     content: "Mental wellness is more than simply the absence of diagnosable mental health conditions.  Rather, it encompasses overall emotional, psychological, and social wellbeing.  Mental wellness impacts how we think, feel, and act and helps to determine how we handle stress, relate to others, and make choices.The goal for the Mental Health Promotion program is to build resilience and life skills and promote connectedness to support mental health wellness of all Kentuckians."
    // },
    // {
    //     format: bodyHelperTypes.title,
    //     order: 20,
    //     content: "Mental Health Promotion Resource Kits"
    // },
    // {
    //     format: bodyHelperTypes.paragraphs,
    //     order: 30,
    //     content: [



    //         <>

    //         <>Browse selected Mental Health Promotion Resources below. To view the full collection, visit <a href="https://drive.google.com/drive/folders/15oWTOSbsdMBM3XPw5uYtSQrZTNsUOjqp">tinyurl.com/MHPResources</a>.</> <br/><br/>
    //         <a href="https://dbhdid.ky.gov/documents/dbh/UnderstandingMentalWellness.pdf">Wellness Resources Guide</a><br /><br />
    //             <a href="https://kyprevention.getbynder.com/m/6de9ee69363ae789/original/BIPOC-Mental-Health-Month-July-2024.pdf">BIPOC Mental Health Month Resource Kit</a><br /><br />
    //             Description: July is BIPOC Mental Health Month, a time to focus on supporting Black, Indigenous, and people of color mental wellness. This mini resource guide provides general information about how to achieve and maintain mental wellness, including how to seek culturally competent mental health care and how culturally-based practices can help to support mental wellness.Visit <a href="https://mhanational.org/bipoc">https://mhanational.org/bipoc</a> for more information and resources.
    //             <br />
    //             <br />
    //             To view the entire collection of Mental Health Promotion and Suicide Prevention resources, visit <a href="https://bit.ly/44bNJOu">https://bit.ly/44bNJOu</a>.
    //         </>,
    //         <><a href="https://kyprevention.getbynder.com/m/7804e24912a271c7/original/Supporting-Mental-Health-and-Preventing-Suicide-Mini-Resource-Kit-2023.pdf">Supporting Mental Health and Preventing Suicide Mini Resource Kit</a><br />
    //             Description: This mini resource kit is flexible in its usage and provides information that can be used to meet the needs of communities to support mental wellness of its residents. It offers guidance in promoting mental wellness among all individuals, supporting connectedness, and developing healthy coping mechanisms and help-seeking behaviors.
    //         </>,
    //         <><a href="https://kyprevention.getbynder.com/m/2db6ba7ac2737761/original/2023-Kentucky-Suicide-Prevention-Awareness-Resource-Guide.pdf">2023 Kentucky Suicide Prevention Awareness Resource Guide</a><br />
    //             Description: The resource guide includes: the history of suicide prevention month, dates within the month that bring awareness, ways to take action, safe messaging, National and State mental wellness and suicide prevention resources and a list of Kentucky events that are happening in September 2023.
    //         </>,

    //         <><a href="https://rb.gy/6paxf">Toolkit for Flooding Disaster Anniversary</a><br />
    //             Description: Toolkit with common reactions and ideas for stress management related to the anniversary of disasters.
    //         </>,


    //         <><a href="https://kyprevention.getbynder.com/transfer/bc40509fd5c030fd15da799d3bf3312760fb130685cdc6210ba05de41bead5f8">Toolkit for Tornado Disaster Anniversary</a><br />
    //             Description: Toolkit with common reactions and ideas for stress management related to the anniversary of disasters.
    //         </>,
    //     ]
    // },
    // {
    //     format: bodyHelperTypes.title,
    //     order: 40,
    //     content: "Helpful Links & Resources"
    // },
    // {
    //     format: bodyHelperTypes.paragraphs,
    //     order: 50,
    //     content: [
    //         <><a href="https://drive.google.com/file/d/14leYXneRepvGsYgTnVmwjtXZ6yvD324g/view?usp=drive_link">Supporting Mental Health for Providers</a><br />
    //             This resource guide is designed for non-behavioral health providers working with individuals who have behavioral health needs. It provides tips for discussing mental health with patients and handouts to provide in the office setting.
    //         </>,

    //         <><a href="https://drive.google.com/file/d/1oK77knznac3B4uEpmLgG2sVUQSFdzFbK/view?usp=drive_link">National Depression and Mental Health Screening Month for Consumers</a><br />
    //         This resource guide includes resources to learn more about the signs of common mental health issues, how to access screens, and tools to monitor and improve your mental health.
    //         </>,

    //         <><a href="https://www.nimh.nih.gov/health/topics/caring-for-your-mental-health">National Institute of Mental Health - Caring for your Mental Health</a><br />
    //             Description: Learn the basics of mental health self-care.
    //         </>,
    //         ,
    //         <><a href="https://www.nih.gov/health-information/your-healthiest-self-wellness-toolkits">National Institute of Mental Health - Your Healthiest Self</a><br />
    //             Description: Find tips for improving emotional, social, physical, and environmental wellness.
    //         </>,
    //         <><a href="https://mhanational.org/workplace/toolkit">Mental Health America - Workplace Wellness Toolkit </a><br />
    //             Descriptions: Toolkit to help support employee mental health and well-being.
    //         </>,
    //         <><a href="https://www.nimh.nih.gov/health/publications/so-stressed-out-fact-sheet">National Institute of Mental Health - I’m So Stressed Out </a><br />
    //             Description: Understand the difference between stress and anxiety and how to manage each.
    //         </>,
    //         <><a href="https://mhanational.org/youth-tech">Mental Health America - Navigating Virtual Spaces for Youth </a><br />
    //             Description: Resources to help young people navigate the virtual world.
    //         </>,
    //         <><a href="https://screening.mhanational.org/screening-tools/?ref=MHAKY">Mental Health America of Kentucky - Mental Health Screening </a><br />
    //             Description: Everyone experiences times of anxiety, stress, and sadness. Knowing when it’s time to seek professional mental health assistance is important.
    //         </>,


    //     ]
    // },
]


export default mHPromotion_Content;