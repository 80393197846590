import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";




const nursingFacilityPASRRRecources_Content = [

    // {
    //     format: bodyHelperTypes.title,
    //     order: 10,
    //     content: "PASRR Regulations and Statues"
    // },
    {
        format: bodyHelperTypes.paragraph,
        order: 20,
        content: <>

            For the MAP-409, MAP-4092, MAP-4093, MAP-4094 AND MAP-4095, please visit the first link below and choose "Provider" from the "Forms" dropdown list near the top of the page, then scroll down to the MAP forms.

            <br />
            <br />

            <Links linkTypeID="717" />

        </>

    }











];

export default nursingFacilityPASRRRecources_Content;