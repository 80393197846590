import React from "react";
import bodyHelperTypes from "../../utils/BodyHelperTypes";
import UnorderedList from "../../layouts/bodyLayouts/helpers/UnorderedList";
import Links from "../../components/Links";




const pASSRRForms_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "PASRR Forms and Resources"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            "Click on the name of a document in the list below to access a copy of the document.",
            <><Links linkTypeID="227" /></>


        ]
    },
    {
        format: bodyHelperTypes.title,
        order: 30,
        content: "PASRR Regulations and Statues"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 40,
        content: [

            <><Links linkTypeID="773" /></>



        ]
    },
    // {
    //     format: bodyHelperTypes.title,
    //     order: 50,
    //     content: "PASRR Regulations and Statues"
    // },
    // {
    //     format: bodyHelperTypes.paragraph,
    //     order: 60,
    //     content: <>

    //         For the MAP-409, MAP-4092, MAP-4093, MAP-4094 AND MAP-4095, please visit the first link below and choose "Provider" from the "Forms" dropdown list near the top of the page, then scroll down to the MAP forms.

    //         <br />
    //         <br />

    //         <Links linkTypeID="717" />

    //     </>
    // },
   









];

export default pASSRRForms_Content;