import React from 'react';
//import EmailreCAPTCHA from '../components/EmailreCAPTCHA.js';

function ProviderContact() {
    const sectionStyle = {
        //border: '1px solid #2f4050',
        padding: '15px',
        margin: '5px 0'
    };
    return (
        <div style={sectionStyle}>
            <div>
                <hr />
                <h5>Provider Update Contact <br />(For Provider Use Only)</h5>
                For assistance in updating your Provider information or to be added to this webpage:
                <br /><br/>
                {/* <a href="mailto:Provider.Service@ky.gov?subject=Provider Update Request">Provider.Service@ky.gov</a><br/><br/> */}
                {/* <EmailreCAPTCHA /> */}
                Click <a href='https://dbhdid.ky.gov/cmhc/dataguide'>here</a> and follow instructions indicated on the most recent Appendix A1 - Provider Site Update Form.
            </div>
        </div>
    );
}

export default ProviderContact;


