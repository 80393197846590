import React from "react";
import bodyHelperTypes from "../../../utils/BodyHelperTypes";


const facilitesHome_Content = [

    {
        format: bodyHelperTypes.title,
        order: 10,
        content: "Overview"
    },
    {
        format: bodyHelperTypes.paragraphs,
        order: 20,
        content: [
            <>
                <p>
                    The department owns several inpatient facilities that provide psychiatric, rehabilitative and nursing care services to its clients.
                </p>
                <p>
                    In addition, the department contracts with a private provider, Appalachian Regional Healthcare in Hazard, to provide up to 100 inpatient psychiatric beds for citizens of 21 eastern Kentucky counties as outlined in 908 KAR 2:040.
                </p>
                <p>
                    For information on a specific facility, see the list below.
                </p>

                <h1 id='Psychiatric Hospitals'>Psychiatric Hospitals</h1>

                <p>
                    These programs provide acute, inpatient psychiatric care for adults who are mentally ill. Many patients are referred through the CMHCs when it has been determined that community services/facilities are not appropriate.
                </p>
                <div class="row ">
                    <div class="col">
                        <p>
                            ARH Psychiatric Center - Hazard<br />
                            <b><a href="mailto:thall8@arh.org ">Tiffany Hall, System Director</a></b><br />
                            102 Medical Center Drive <br />
                            Hazard, Ky 41701 <br />
                            <b>Phone:</b> (606) 439-6712 <br />
                            <b>Fax:</b>  (606) 439-5765 <br />
                            <a href='https://www.arh.org/behavioral-health'>https://www.arh.org/behavioral-health <br /></a>
                        </p>
                    </div>
                    <div class="col">
                        <p>
                            Central State Hospital<br />
                            <b>Matt Mooring, Director</b><br />
                            10510 LaGrange Road <br />
                            Louisville, KY 40223-1228<br />
                            <b>Phone:</b> (502) 253-7026 <br />
                            <b>Fax:</b>  (502) 253-7049 <br />
                        </p>
                    </div>


                </div>


                <div class="row ">
                    <div class="col">
                        <p>
                            Eastern State Hospital<br />
                            <b>Lindsey Jasinski, CAO</b><br />
                            1350 Bull Lea Road <br />
                            Lexington, KY 40511 <br />
                            <b>Phone:</b> (859) 246-8000 <br />
                            <b>Fax:</b>  (859) 246-8032 <br />
                            <a href='http://ukhealthcare.uky.edu/eastern-state-hospital/'>http://ukhealthcare.uky.edu/<br />eastern-state-hospital/</a>
                        </p>
                    </div>
                    <div class="col">
                        <p>
                            Western State Hospital<br />
                            <b>Rebecca Frost, Director</b><br />
                            2400 Russellville Road <br />
                            P.O. Box 2220 <br />
                            Hopkinsville, KY 42241-2200 <br />
                            <b>Phone:</b> (270) 889-6025 <br />
                            <b>Fax:</b>  (270) 886-4487 <br />
                            <a href='http://westernstatehospital.ky.gov/'>http://westernstatehospital.ky.gov/</a>
                        </p>
                    </div>
                </div>


                <h1 id='Intermediate Care Facilities for Individuals With Intellectual Disabilities (ICF/IID)'>Intermediate Care Facilities for Individuals With Intellectual Disabilities (ICF/IID)</h1>

                <p>

                    These programs are designed to provide comprehensive and individualized health care, training and habilitation services to individuals to promote their functional skills and independence.
                </p>



                <div class="row ">
                    <div class="col">
                        <p>
                            Bingham Gardens<br />
                            <b>Kelly T. Mangum, Director</b><br />
                            4407 Louise Underwood Way <br />
                            Louisville, KY 40216<br />
                            <b>Phone:</b> (502) 361-2301 <br />
                            <b>Fax:</b>  (502) 366-0367 <br />
                            <a href='https://hazelwood.org/'>https://hazelwood.org/</a>

                        </p>
                    </div>
                    <div class="col">
                        <p>
                            Hazelwood ICF<br />
                            <b>Kelly Mangum, Director</b><br />
                            1800 Old Bluegrass Center <br />
                            P.O. Box 14506 <br />
                            Louisville, KY 40215-1130<br />
                            <b>Phone:</b> (502) 361-2301 <br />
                            <b>Fax:</b>  (502) 366-0367 <br />
                            <a href='https://hazelwood.org/'>https://hazelwood.org/</a>
                        </p>
                    </div>
                </div>

                <div class="row ">
                    <div class="col">
                        <p>
                            Oakwood ICF<br />
                            <b><a href="mailto:Darla.Crabtree@newvista.org ">Darla Crabtree, Director</a></b><br />
                            2441 US 27 South HWY 27<br />
                            Somerset, KY 42501-2935<br />
                            <b>Phone:</b> (606) 677-4068 <br />
                            <b>Fax:</b>  (606) 677-4148 <br />
                            <a href='https://oakwoodky.org/'>https://oakwoodky.org/</a>


                        </p>
                    </div>
                    <div class="col">
                        <p>
                            Outwood ICF<br />
                            <b>Kellie Stricker, Director</b><br />
                            23524 Dawson Springs Road<br />
                            Dawson Springs, KY 42408-0524 <br />
                            <b>Phone:</b> (270) 797-3771 <br />
                            <b>Fax:</b>  (270) 797-3592 <br />
                            <a href='https://rescarecommunityliving.com/location/rescare-community-living-outwood-dawson-springs-ky/'>https://rescarecommunityliving.com/location/rescare-community-living-outwood-dawson-springs-ky/</a>
                        </p>
                    </div>
                </div>


                <h1 id='Nursing Facilities or Intermediate Care Facilities (ICFs)'>Nursing Facilities or Intermediate Care Facilities (ICFs)</h1>

                <p>

                    These programs provide long-term medical care for mentally ill persons who also require treatment or supervision of the mental illness. Criteria for admission is inpatient status in other state-operated facilities.

                </p>


                <div class="row ">
                    <div class="col">
                        <p>
                            Glasgow SNF<br />
                            <b><a href="mailto:Amanda.allen@ky.gov "></a>Amanda Allen, Director</b><br />
                            207 State Avenue<br />
                            P.O. Box 189<br />
                            Glasgow, KY 42141<br />
                            <b>Phone:</b> (270) 659-4700 <br />
                            <b>Fax:</b>  (270) 651-1726 <br />
                            <a href='http://glasgowsnf.ky.gov/'>http://glasgowsnf.ky.gov/</a>

                        </p>
                    </div>
                    <div class="col">
                        <p>
                            Western SNF<br />
                            <b>Terri Barbee, Director</b><br />
                            2400 Russellville Road<br />
                            Hopkinsville, KY 42241 <br />
                            <b>Phone:</b> (270) 889-2990 <br />
                            <b>Fax:</b>  (270) 886-7910 <br />
                            <a href='http://westernstatehospital.ky.gov/'>http://westernstatehospital.ky.gov/</a>
                        </p>
                    </div>
                </div>

                <h1 id='Intermediate Care Facility Specialty Clinics'>Intermediate Care Facility Specialty Clinics</h1>

                <p>

                    The specialty clinics provide evidence-based, holistic health care for people with intellectual and other developmental disabilities living in the facility or community.


                </p>


                <div class="row ">
                    <div class="col">
                        <p>
                            Oakwood Specialty Intermediate Care Clinic<br />
                            <b>Adam Wright, Director</b><br />
                            2441 S. Highway 27<br />
                            Somerset, KY 42501<br />
                            <b>Phone:</b> (606) 677-4214 <br />
                            <b>Fax:</b>  (606) 677-4148 <br />
                            <a href='http://www.oakwoodky.org/specialty-medical-clinic/getting-started'>http://www.oakwoodky.org/<br />
                                specialty-medical-clinic/getting-started</a>

                        </p>
                    </div>
                    <div class="col">
                        <p>
                            Lee Specialty Clinic<br />
                            <b>Lynzee Cornell, CEO</b><br />
                            4501 Louise Underwood Way<br />
                            Louisville, KY 40216 <br />
                            <b>Phone:</b> (502) 368-2348 <br />
                            <a href='http://www.leespecialtyclinic.com'>http://www.leespecialtyclinic.com</a>
                        </p>
                    </div>
                </div>


                <div class="row ">
                    <div class="col">
                        <p>
                            Hazelwood Center Specialty Intermediate Care Clinic<br />
                            <b>Kelly Magum, Facility Director</b><br />
                            Louisville, KY 40215-1130<br />
                            <b>Phone:</b> (502) 361-2301 <br />
                            <b>Fax:</b>  (502) 366-0367 <br />
                            <a href='https://hazelwood.org/'>https://hazelwood.org/</a>
                        </p>
                    </div>
                </div>


                <h1 id='Forensic Psychiatric Hospital'>Forensic Psychiatric Hospital</h1>

                <p>

                    Kentucky Correctional Psychiatric Center (KCPC) is a licensed psychiatric hospital that conducts forensic competency evaluations, competency restoration, and criminal responsibility evaluations for pre-trial patients. The facility also provides inpatient treatment for individuals who have been adjudicated incompetent to stand trial and are held on a civil commitment order. All patients at KCPC are court-ordered under KRS 504.080, KRS 504.110, or KRS 202C. KCPC serves all 120 counties and is located in a secure facility on the grounds of Luther Luckett Correctional Complex in Lagrange, Kentucky.
                </p>



                <div class="row ">
                    <div class="col">
                        <p>
                            Kentucky Correctional Psychiatric Center<br />
                            <b>Koleen Slusher, Director</b><br />
                            1612 Dawkins Road<br />
                            P.O. Box 67<br />
                            LaGrange, KY 40031 <br/>
                            <b>Phone:</b> (502) 222-0067 <br />
                            <b>Fax:</b>  (502) 222-7798 <br />
                        </p>
                    </div>
                </div>

            </>
        ]
    },
];

export default facilitesHome_Content;